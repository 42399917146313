require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

import "bootstrap";


// **********************************************************************
// activate navbar color change toggle
// **********************************************************************

import { styleNavbar } from '../components/navbar';
styleNavbar();

// **********************************************************************
// activate hamburger animation and overlay menu display/hide
// **********************************************************************

import { animHamburger } from '../components/animHamburger';
animHamburger();

// **********************************************************************
// activate questionnaire animation
// **********************************************************************

import { animQuestionnaire } from '../components/animQuestionnaire';
animQuestionnaire();

// **********************************************************************
// activate text banner animation
// **********************************************************************

import { animTextBanner } from '../components/animTextBanner';
animTextBanner();

// **********************************************************************
// initialize mapbox
// **********************************************************************

import { initMapbox } from '../components/initMapbox';
initMapbox();

// // **********************************************************************
// // activate function to synchonize use cases and home video
// // **********************************************************************

// import { launchTextType } from '../components/textTyped';
// launchTextType();


// // **********************************************************************
// // activate overlay toggle in the footer
// // **********************************************************************

// import { overlayFooterAnimation } from '../components/overlayFooter';
// overlayFooterAnimation();


// // **********************************************************************
// // activate overlay navigation with hamburger/close buttons
// // **********************************************************************

// import { navButton } from '../components/navbarMenu';
// navButton();


// **********************************************************************
// activate address autocomplete in signup form
// **********************************************************************

import { initSignupAddressAutocomplete } from '../components/signupAddressAutocomplete';
initSignupAddressAutocomplete();


// // **********************************************************************
// // activate function to show/hide cookie banner
// // **********************************************************************

// import { removeCookieBanner } from '../components/cookieBanner';
// removeCookieBanner();
