// **********************************************************************
// HELPER FUNCTION TO SHOW OR HIDE OVERLAY WITH SLIDE EFFECT
// **********************************************************************

const overlayAnimation = () => {
  // retrieve hamburger, overlay menu and each links from the DOM
  const overlayMenu = document.getElementById('overlay-navigation');
  const overlayLinks = document.querySelectorAll('.overlay-link');
  const hamburger = document.getElementById('hamburger');
  // remove class right and add class left to open overlay
  if (overlayMenu.classList.contains('overlay-slide-right')) {
    overlayMenu.classList.remove('overlay-slide-right');
    overlayMenu.classList.add('overlay-slide-left');
    //event listener to close overlay when click on an item
    overlayLinks.forEach(item => {
      item.addEventListener('click', () => {
        overlayMenu.classList.remove('overlay-slide-left');
        overlayMenu.classList.add('overlay-slide-right');
        // remove is active class from hamburger
        hamburger.classList.remove('is-active');
      });
    });
  // remove class left and add class right to close overlay
  } else {
    overlayMenu.classList.remove('overlay-slide-left');
    overlayMenu.classList.add('overlay-slide-right');
  }
}

export { overlayAnimation };
