// **********************************************************************
// FUNCTION TO ACTIVATE ADDRESS AUTOCOMPLETE IN SIGNUP FORM
// **********************************************************************

import places from 'places.js'

const initSignupAddressAutocomplete = () => {
  const userAddress = document.getElementById('user-address');
  if (userAddress) {
    places({ container: userAddress })
  }
}

export { initSignupAddressAutocomplete };
