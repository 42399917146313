// **********************************************************************
// HELPER FUNCTION TO GO TO NEXT QUESTION IN BANNER QUESTIONNAIRE
// **********************************************************************

const pickQuestion = (questionnaireIndex, questions, images, indicators) => {
// display questions
  questions.forEach(question => {
    if (questionnaireIndex === parseInt(question.dataset.index)) {
      question.classList.remove("display-hide");
    } else {
      question.classList.add("display-hide");
    }
  });

  // display images
  images.forEach(image => {
    if (questionnaireIndex === parseInt(image.dataset.index)) {
      image.classList.remove("display-hide");
    } else {
      image.classList.add("display-hide");
    }
  });

  // update indicators
  indicators.forEach(indicator => {
    if (questionnaireIndex === parseInt(indicator.dataset.index)) {
      indicator.classList.add("background-white");
    } else {
      indicator.classList.remove("background-white");
    }
  });
}

export { pickQuestion };
