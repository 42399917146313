// **********************************************************************
// FUNCTION TO ACTIVATE COLOR SWITCH
// **********************************************************************

const styleNavbar = () => {
  const navbar = document.getElementById('navbar');
  const minimalNavbar = document.getElementById('navbar-minimal');
  const hamburger = document.getElementById('hamburger');
  const white = document.querySelector('.get-nav-white');
  const black = document.querySelector('.get-nav-black');
  // handle standard navbar
  if (navbar) {
    // handle black navbar
    if (black) {
      navbar.classList.remove('navbar-platform-white');
      navbar.classList.add('navbar-platform-black');
      hamburger.classList.remove('hamburger-black');
      hamburger.classList.add('hamburger-white');
      document.body.style.background = 'black';
      document.body.style.color = 'white';

    }
    // handle white navbar
    if (white) {
      navbar.classList.remove('navbar-platform-black');
      navbar.classList.add('navbar-platform-white');
      hamburger.classList.remove('hamburger-white');
      hamburger.classList.add('hamburger-black');
      document.body.style.background = 'white';
      document.body.style.color = 'black';
    }
  }
  // handle minimal navbar (for questionnaire period)
  if (minimalNavbar) {
    if (black) {
      minimalNavbar.classList.remove('navbar-platform-white');
      minimalNavbar.classList.add('navbar-platform-black');
      document.body.style.background = 'black';
      document.body.style.color = 'white';
    }
    if (white) {
      minimalNavbar.classList.remove('navbar-platform-black');
      minimalNavbar.classList.add('navbar-platform-white');
      document.body.style.background = 'white';
      document.body.style.color = 'black';
    }
  }
}

export { styleNavbar };
