// **********************************************************************
// FUNCTION TO ANIMATE QUESTIONNAIRE
// **********************************************************************

// import helper function to display accurate question
import { pickQuestion } from '../helpers/pickQuestion';

// global variable for index
var questionnaireIndex = 0;

// main function
const animQuestionnaire = () => {
  // collect elements from DOM
  const bannerQuestionnaire = document.getElementById('home-questionnaire');
  const start = document.getElementById('questionnaire-start');
  const questions = document.querySelectorAll('.questionnaire-questions');
  const images = document.querySelectorAll('.questionnaire-images');
  const indicators = document.querySelectorAll('.indicator');
  const previousButtons = document.querySelectorAll('.questionnaire-previous');
  const nextButtons = document.querySelectorAll('.questionnaire-next');

  if (bannerQuestionnaire) {

    // handle go to start question on page load
    window.addEventListener('load', () => {
      questionnaireIndex = 0;
      pickQuestion(questionnaireIndex, questions, images, indicators);
    });

    // handle questionnaire start
    start.addEventListener('click', () => {
      questionnaireIndex = 1;
      pickQuestion(questionnaireIndex, questions, images, indicators);
    });

    // handle go to next question with click button next
    nextButtons.forEach(nextButton => {
      nextButton.addEventListener('click', () => {
        questionnaireIndex += 1;
        pickQuestion(questionnaireIndex, questions, images, indicators);
      });
    });

    // handle go to previous question with click button previous
    previousButtons.forEach(previousButton => {
      previousButton.addEventListener('click', () => {
        questionnaireIndex -= 1;
        pickQuestion(questionnaireIndex, questions, images, indicators);
      });
    });
  }
}

export { animQuestionnaire };
