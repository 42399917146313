// **********************************************************************
// FUNCTION TO ANIMATE HAMBURGER AND DISPLAY/HIDE OVERLAY NAVIGATION
// **********************************************************************

// import helper function to open/close overlay
import { overlayAnimation } from '../helpers/overlayAnimation';

// main function to animate hamburger and toggle overlay
const animHamburger = () => {
  // retrieve hamburger from DOM
  const hamburger = document.getElementById('hamburger');
  // event listener to toggle on click
  if (hamburger) {
    hamburger.addEventListener('click', () => {
      hamburger.classList.toggle('is-active');
      overlayAnimation();
    });
  }
}

export { animHamburger };
