// **********************************************************************
// HELPER FUNCTION TO SHOW A SECTION OF THE BANNER TEXT
// **********************************************************************

const showBannerTextSection = (items, anim) => {
  items.forEach(item => {
    item.classList.remove('display-hide-important');
    item.classList.add(anim);
  });
}

export { showBannerTextSection };
