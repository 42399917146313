// **********************************************************************
// HELPER FUNCTION TO HIDE A SECTION OF THE BANNER TEXT
// **********************************************************************

const hideBannerTextSection = (items, anim) => {
  items.forEach(item => {
    item.classList.add('display-hide-important');
    item.classList.remove(anim);
  });
}

export { hideBannerTextSection };
