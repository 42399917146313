// **********************************************************************
// FUNCTION TO ANIMATE TEXT BANNER
// **********************************************************************

// import helper function to show a section
import { showBannerTextSection } from '../helpers/showBannerTextSection';
// import helper function to hide a section
import { hideBannerTextSection } from '../helpers/hideBannerTextSection';

// main function
const animTextBanner = () => {
  const textBanner = document.getElementById('text-banner');
  if (textBanner) {
    const quoteItems = document.querySelectorAll('.quote');
    const firstItems = document.querySelectorAll('.first-wording');
    const secondItems = document.querySelectorAll('.second-wording');
    const presentItems = document.querySelectorAll('.presents');
    const illustrationItems = document.querySelectorAll('.illustration');
    const anim = "banner-text-animated-anim";
    // quote
    setTimeout(function() {
      showBannerTextSection(quoteItems, anim);
    }, 100);
    // first wording
    setTimeout(function() {
      hideBannerTextSection(quoteItems, anim);
      showBannerTextSection(firstItems, anim);
    }, 3100);
    // second wording
    setTimeout(function() {
      hideBannerTextSection(firstItems, anim);
      showBannerTextSection(secondItems, anim);
    }, 6100);
    // presents
    setTimeout(function() {
      hideBannerTextSection(secondItems, anim);
      showBannerTextSection(presentItems, anim);
    }, 9100);
    // illustrations
    setTimeout(function() {
      hideBannerTextSection(presentItems, anim);
      showBannerTextSection(illustrationItems, anim);
    }, 12100);
    // hide illustration
    setTimeout(function() {
      hideBannerTextSection(illustrationItems, anim);
    }, 17100);
    // restart main function
    var timeout = setTimeout(animTextBanner, 17100);
  }
}

export { animTextBanner };
